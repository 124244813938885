*:not([data-scrollbar="true"]), [data-scrollbar="false"] {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

[data-scrollbar="true"] {
  scrollbar-width: thin;
  scrollbar-color: var(--theme-background-color-less-2) transparent;
}

:not([data-scrollbar="true"])::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

[data-scrollbar="true"]::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--theme-background-color-less-2);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--theme-primary-background-color);
}

*:focus {
  outline-color: #49d3b4;
}

*[tabIndex^="-1"]:focus {
  outline: none;
}

html, body, #root, #root > div {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #1F2229;
  cursor: default;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

a:hover, a:hover > * {
  cursor: pointer;
  text-decoration: none;
}

input:focus {
  outline: none !important;
}

[data-touchable] {
  cursor: default;
}

a [data-touchable] {
  cursor: pointer;
}

[data-touchable="true"][data-touchable-onpress="true"] {
  cursor: pointer;
}

[data-switch="true"][data-switch-disabled="true"],
[data-touchable="true"][data-touchable-disabled="true"] {
  cursor: not-allowed;
}

[data-touchable="true"][data-touchable-disabled="false"][data-touchable-onpress="true"] > [data-touchable="true"][data-touchable-disabled="true"],
a > [data-touchable="true"][data-touchable-disabled="true"] {
  cursor: unset;
}

[data-card-link="true"]:focus {
  outline: none;
  outline-color: transparent;
}

/* https://github.com/necolas/react-native-web/issues/1299 */
[data-paging-enabled-fix="true"]  > div > div > div {
  height: 100%;
}

.StripeElement {
  align-self: stretch;
}